import axios from "axios";
import config from "../../config/config.json";
import {useEffect, useState} from "react";
import InvoiceItemInGrid from "./InvoiceItemInGrid";
import * as items from "@progress/kendo-data-query/dist/npm/transducers";
import {PDFExport} from "@progress/kendo-react-pdf";
import {Button} from "@progress/kendo-react-buttons";
import InvoiceSums from "./InvoiceSums";
import ProjectSums from "./ProjectSums";
import {isDate} from "@progress/kendo-data-query/dist/npm/utils";
import InvoiceSetItems from "./InvoiceSetItems";

const InvoiceItems = props => {

    const includeNoNB = props.includeNoNB
    const period = props.period
    const periodFrom = props.periodFrom
    const selectedAuthor = props.selectedAuthor
    const periodTo = props.periodTo
    const setPdfDownloaded = props.setPdfDownloaded
    const reportType = props.reportType
    const selectedClient = props.selectedClient

    //debugger

    const convertDateToUTC = (date) => {

        if (isDate(date)) {
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = date.getDate();

            const utcDate = new Date(Date.UTC(year, month, day));

            return utcDate;
        } else {
            return null
        }

    };

    const projectIDs = props.projectIDs.filter(item => item.checked).map(item => item.id).join(',');
    const selected = {"projects": projectIDs, "includeInvoiced": props.includeInvoiced.id, "nonBillable": includeNoNB.id, "period": period.id, "periodFrom": convertDateToUTC(periodFrom), "periodTo": convertDateToUTC(periodTo), "selectedAuthor": selectedAuthor?.ID, "ClientID": selectedClient?.ID}
    const [data, setData] = useState()
    const [dataIDs, setDataIDs] = useState("")


    let tmsData = null

    useEffect(() => {
        getData()
    }, []);

    async function getData() {
        try {
            console.log("before data set", selected)
            const response = await axios.post(config.API_URL + 'invoice/get_items_for_invoice_recap', selected, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            const newData = response.data
            const newDataII2 = newData.InvoiceItems.map(item => ({...item, Date: new Date(item.Date)}))
            // const newData2 = {...newData, DateFrom: new Date(newData.DateFrom), DateTo: new Date(newData.DateTo), InvoiceItems: newDataII2}
            const newData2 = {...newData, InvoiceItems: newDataII2}
            setData(newData2);
            console.log("data set")
        } catch (error) {
            //set({ error: error.message, isLoading: false });
            console.log("data set error", error)
        }
    }

    let pdfExportComponent;

    const handleExportWithComponent = (event) => {
        pdfExportComponent.save();
        setPdfDownloaded(true)
    }

    return(
        <div className="invoice-items-outer">

            <PDFExport ref={(component) => (pdfExportComponent = component)}
                       paperSize="A4"
                       margin={{top: 30, left: 20, right: 20, bottom: 30}}
                       keepTogether={"span"}
            >
                {data &&
                    // <div className="invoice-items">
                    //     {data.sort((a, b) => new Date(a.Date) - new Date(b.Date)).map(record => (
                    //         // <div key={record.ID}>{record.text}</div>
                    //         <InvoiceItemInGrid key={record.ID} item={record} reportType={reportType} />
                    //     ))}
                    //
                    //     <div className="invoice-sum">
                    //         {/*Celkem [CZK]: {data.reduce((total, item) => total + item.Price, 0).toLocaleString()}*/}
                    //         {reportType.id !==3 && <InvoiceSums invoices={data} setInvoiceIds={props.setInvoiceIds}/>}
                    //     </div>
                    //
                    // </div>

                    <div className="invoice-items-inner">
                        <div className="invoice-items-inner-caption">Přehled úkonů právní služby</div>
                        <h2>Klient: {data.ClientName}</h2>
                        <h2>Období: {data.DateFrom === "" ? "..." : data.DateFrom} - {data.DateTo === "" ? "..." : data.DateTo}</h2>
                        <h2>Pracovník: {data.AuthorName === "All" ? "Všichni pracovníci" : data.AuthorName}</h2>
                        <h2>Status: {data.IncludeInvoiced === 2 ? "Všechny položky" : "Pouze nefakturované položky"}</h2>
                        <div className="invoice-items">
                            {Object.entries(
                                data.InvoiceItems.filter(item => item.ItemType === "prace").sort((a, b) => new Date(a.Date) - new Date(b.Date))
                                    .reduce((acc, item) => {
                                        // Group by project
                                        (acc[item.ProjectName] = acc[item.ProjectName] || []).push(item);
                                        return acc;
                                    }, {})
                            ).map(([project, items]) => (
                                <div key={project} className="project-section">
                                    <h3>Project: {project}</h3> {/* Project Name */}
                                    {items.map(record => (
                                        <InvoiceItemInGrid key={record.ID} item={record} reportType={reportType}/>
                                    ))}

                                    <div>
                                        <ProjectSums invoices={items} project={project} reportType={reportType}/>
                                    </div>

                                </div>
                            ))}


                            <div className="invoice-sum">
                                <InvoiceSums invoices={data.InvoiceItems.filter(item => item.ItemType === "prace")}
                                             reportType={reportType} setInvoiceIds={props.setInvoiceIds}/>
                            </div>

                        </div>

                        <br/><br/>

                        <div className="invoice-items-inner-caption">Přehled výdajů</div>
                        <div className="invoice-items">
                            {Object.entries(
                                data.InvoiceItems.filter(item => item.ItemType === "material").sort((a, b) => new Date(a.Date) - new Date(b.Date))
                                    .reduce((acc, item) => {
                                        // Group by project
                                        (acc[item.ProjectName] = acc[item.ProjectName] || []).push(item);
                                        return acc;
                                    }, {})
                            ).map(([project, items]) => (
                                <div key={project} className="project-section">
                                    <h3>{project}</h3> {/* Project Name */}
                                    {items.map(record => (
                                        <InvoiceItemInGrid key={record.ID} item={record} reportType={reportType}
                                                           showHours="no" showPrice="yes"/>
                                    ))}

                                    <div>
                                        <ProjectSums invoices={items} project={project} reportType={reportType}
                                                     showHours="no" showPrice="yes"/>
                                    </div>

                                </div>
                            ))}


                            <div className="invoice-sum">
                                <InvoiceSums invoices={data.InvoiceItems.filter(item => item.ItemType === "material")}
                                             reportType={reportType} setInvoiceIds={props.setInvoiceIds} showHours="no"
                                             showPrice="yes"/>
                            </div>

                            <InvoiceSetItems invoices={data.InvoiceItems} setInvoiceIds={props.setInvoiceIds}/>

                        </div>

                    </div>


                }
            </PDFExport>

            <div className="invoice-get-pdf-button">
                <Button onClick={handleExportWithComponent} size={"large"}>get PDF</Button>
            </div>

            {/*<div className="invoice-get-excel-button">*/}
            {/*    <Button onClick={handleExportWithComponent} size={"large"}>get Excel</Button>*/}
            {/*</div>*/}

            {dataIDs}

        </div>
    )
}

export default InvoiceItems